import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Link } from 'gatsby';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import SEO from '../components/Seo/Seo';
import errorImg from '../images/error-graphic.png';
import errorImg2x from '../images/error-graphic@2x.png';

const Main = styled(Card)(
  css({
    borderRadius: '20px',
    boxShadow: '0 0 26px rgba(0,0,0,.1)',
    position: 'relative',
    px: [2, null, null, 6],
    zIndex: 'docked',
  }),
);

const ImageWrapper = styled.div(
  css({
    mx: 'auto',
    my: 10,
    width: 'fit-content',
  }),
);

const TextWrapper = styled.div(
  css({
    maxWidth: '400px',
    mb: 8,
    mx: 'auto',
    textAlign: 'center',
  }),
);

const NotFoundPage = () => (
  <Flex justifyContent="center">
    <SEO title="404 page not found" />
    <Main>
      <Card.Body py={12}>
        <Typography variant="h1" color="secondary.main" textAlign="center">
          404 <strong>page not found</strong>
        </Typography>
        <ImageWrapper>
          <Image
            src={errorImg}
            srcSet={`${errorImg} 1x, ${errorImg2x} 2x`}
            alt="404"
            responsive
          />
        </ImageWrapper>
        <TextWrapper>
          <Typography>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </Typography>
        </TextWrapper>
        <Button as={Link} to="/" variant="text" variantColor="primary" block>
          Back
        </Button>
      </Card.Body>
    </Main>
  </Flex>
);

export default NotFoundPage;
